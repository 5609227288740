body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  box-sizing: border-box;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  background-color: #ffffff;
  color: #0052a4;
  border: 1px solid #0052a4;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
  box-sizing: border-box;
}

.logo {
  width: 250px;
  max-width: 80%;
  height: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.contact-text {
  font-size: 18px;
  margin-bottom: 10px;
}

.callback-info {
  font-size: 14px;
  margin: 10px 0;
  color: #0052a4;
}

.button-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  align-items: center;
}

.button {
  color: #0052a4;
  padding: 15px 30px;
  margin: 10px 0;
  border: 2px solid #0052a4;
  border-radius: 5px;
  background-color: transparent;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
}

#map {
  height: 100vh;
  width: 100vw;
}

.map-container {
  height: 100vh;
  width: 100vw;
}

#map {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.map-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.button:hover {
  background-color: #0052a4;
  color: white;
}

.icon {
  margin-right: 10px;
}

.callback-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

.input-group {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 15px;
}

.callback-form select,
.callback-form input {
  padding: 10px;
  margin: 10px 0;
  border: 2px solid #0052a4;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: transparent;
  width: 100%;
}

.callback-form button.full-width {
  width: 100%;
  margin-top: 10px;
}

.button-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.back-button {
  margin-right: 10px;
}

.callback-form button {
  color: #0052a4;
  padding: 15px 30px;
  border: 2px solid #0052a4;
  border-radius: 5px;
  background-color: transparent;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
  width: 48%;
  box-sizing: border-box;
}

.callback-form button:hover {
  background-color: #0052a4;
  color: white;
}

.address-list {
  width: 100%;
  margin-top: 20px;
  overflow-x: auto;
}

.address-list table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.address-list th, .address-list td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.address-list th {
  background-color: #f2f2f2;
  cursor: pointer;
}

.address-list th.sort-ascending::after {
  content: ' ▲';
}

.address-list th.sort-descending::after {
  content: ' ▼';
}

.address-list tr:nth-child(even) {
  background-color: #f9f9f9;
}

.address-list tr:hover {
  background-color: #f1f1f1;
}

.delete-button {
  color: #fff;
  background-color: #ff4d4d;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.delete-button:hover {
  background-color: #ff1a1a;
}

@media (max-width: 768px) {
  .button {
    font-size: 16px;
  }
  .input-group {
    flex-direction: column;
  }
  .callback-form select,
  .callback-form input {
    width: 100%;
  }
  .logo {
    width: 200px;
    max-width: 80%;
    height: auto;
  }
}

@media (max-width: 480px) {
  .button {
    font-size: 14px;
    padding: 8px 16px;
  }
  .contact-text {
    font-size: 14px;
  }
}

#map {
  height: 100vh;
  width: 100%;
}

.map-container {
  height: 100vh;
  width: 100%;
}

.thumbnail button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(255, 0, 0, 0.7);
  color: white;
  border: none;
  border-radius: 3px;
  padding: 2px 5px;
  cursor: pointer;
}

.thumbnail {
  position: relative;
  display: inline-block;
  margin: 5px;
}

.thumbnail img {
  max-width: 100px;
  max-height: 100px;
}

.thumbnail-remove-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(255, 0, 0, 0.7);
  color: white;
  border: none;
  border-radius: 3px;
  padding: 2px 5px;
  cursor: pointer;
  font-size: 0.2em; 
}

.fixed-size-image {
  width: 300px; 
  height: 150px; 
  object-fit: cover; 
}

.custom-file-upload {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  border: 1px solid #0052a4;
  border-radius: 4px;
  background-color: #ffffff;
  color: #0052a4;
}

input[type="file"] {
  display: none; 
}

.table-container table {
  font-size: 0.8rem; 
}

.table-container th,
.table-container td {
  padding: 8px 12px; 
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
