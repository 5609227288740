/* src/compo_styles/FormStyles.css */

.input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    width: 100%;
  }
  
  .input-group input,
  .input-group select,
  .input-group textarea {
    padding: 10px;
    border: 2px solid #0052a4;
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 5px;
  }
  
  .input-group input[type="file"] {
    padding: 5px;
    border: none;
  }
  
  .input-group input:focus,
  .input-group select:focus,
  .input-group textarea:focus {
    outline: none;
    border-color: #003d73;
  }
  
  .button-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .button-group .button {
    color: #0052a4;
    padding: 15px 30px;
    border: 2px solid #0052a4;
    border-radius: 5px;
    background-color: transparent;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    transition: background-color 0.3s ease, color 0.3s ease;
    cursor: pointer;
    width: 48%;
    box-sizing: border-box;
  }
  
  .button-group .button:hover {
    background-color: #0052a4;
    color: white;
  }
  
  .button-group .back-button {
    margin-right: 10px;
  }
  

.phone-number-group {
  display: flex;
  align-items: center; 
  gap: 10px; 
  margin-bottom: 15px; 
}

.phone-number-group .country-code {
  flex: 0 0 auto; 
  width: auto; 
}

.phone-number-group input {
  flex: 1; 
  min-width: 0; 
}
