/* src/styles/PhotoUpload.css */

.photo-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .photo-upload-label {
    color: #0052a4;
    padding: 15px 30px;
    border: 2px solid #0052a4;
    border-radius: 5px;
    background-color: transparent;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    transition: background-color 0.3s ease, color 0.3s ease;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
  }
  
  .photo-upload-label:hover {
    background-color: #0052a4;
    color: white;
  }
  
  .thumbnails {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  
  .thumbnail {
    position: relative;
    margin: 5px;
  }
  
  .thumbnail img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
    border: 2px solid #ddd;
  }
  
  .thumbnail button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: rgba(255, 0, 0, 0.7);
    color: white;
    border: none;
    border-radius: 3px;
    padding: 2px 5px;
    cursor: pointer;
  }
  
  .thumbnail button:hover {
    background-color: rgba(255, 0, 0, 1);
  }
  